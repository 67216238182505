/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, ColumnCover, ColumnDiv, PriceWrap, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1iwcpu3 --full pb--30 pt--50" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--220" content={"<span style='color: white'>Janka &Tomáš</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 w--300 mt--30" href={"#kontakt"} content={"Potvrdit účast"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"informace"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 w--300 ls--0" style={{"maxWidth":880}} content={"<span style='color: var(--color-dominant)'>Srdečně Vás zveme na naši velkolepou svatbu</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"informace-2"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7952/2ca4789b6f244304bca958157045f5b9_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--154" content={"<span style='color: white'>21.1.</span>"}>
                    </Title>

                    <Title className="title-box fs--62 mt--02" content={"<span style='color: white'>od 13:00</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7952/038e0bf10a484fe2b6c74c8c7d2401df_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7952/038e0bf10a484fe2b6c74c8c7d2401df_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7952/038e0bf10a484fe2b6c74c8c7d2401df_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7952/038e0bf10a484fe2b6c74c8c7d2401df_s=860x_.jpg 860w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--43" content={"<span style='color: white'>Kostel sv.</span>"}>
                    </Title>

                    <Title className="title-box fs--128 mt--02 pl--0 pr--0" content={"<span style='color: white'>MATĚJE</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--80" name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"Harmonogram"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-supplementary);\">Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 pt--0 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mb--0 mt--0 pb--0 pt--0">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7952/8b8f27ff3b5e44e49a8b9aed437bdd57_con=109_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"program-3"}>
          
          <ColumnWrap className="column__flex --center el--4 flex--center" style={{"maxWidth":1300}} columns={"4"}>
            
            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"14:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"15:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"16:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"17:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"18:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"19:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"20:00"}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--20 pl--20 pr--20">
              
              <Title className="title-box fs--86" content={"..."}>
              </Title>

              <Text className="text-box ls--01 lh--2 mt--10" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wydf34 bg--center pb--80 pt--80" name={"obrazek"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/78/img-3_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pb--80 pt--100 flex--center" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--80" name={"polozky"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style='color: var(--color-dominant)'>Svatební dary</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style='color: var(--color-dominant)'>Dary opravdu nejsou nutné. V prvním řadě chceme, aby jste přijeli! Pokud však na daru trváte, moc nás potěší cokoliv ze seznamu níže.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" name={"polozky-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--1 mt--0" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="mb--80 mt--0">
              
              <PriceWrap className="pb--12 pl--20 pr--20 pt--12 flex--center" style={{"backgroundColor":"var(--color-blend--05)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Sada porcelánových talířů</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn2 fs--18" content={"Zamluvit"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--12 pl--20 pr--20 pt--12 flex--center" style={{"backgroundColor":"var(--color-blend--05)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Rychlovarná konvice</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn2 fs--18" content={"Zamluvit"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--12 pl--20 pr--20 pt--12 flex--center" style={{"backgroundColor":"var(--color-blend--05)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Stříbrný jídelní příbor</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn2 fs--18" content={"Zamluvit"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--12 pl--20 pr--20 pt--12" style={{"backgroundColor":"var(--color-blend--05)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Sada porcelánových talířů</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Již zamluveno. Díky!</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--12 pl--20 pr--20 pt--12 flex--center" style={{"backgroundColor":"var(--color-blend--05)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Sada porcelánových talířů</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn2 fs--18" content={"Zamluvit"}>
                  </Button>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":1080}} content={"Obdrželi jste pozvánku? Potvrďte svou účast!"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 fs--22" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Kód z pozvánky","type":"text","placeholder":"Zadejte svůj identifikační kód z pozvánky"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/78/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/78/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/78/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/78/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/78/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/78/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Těšíme se na Vás!</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--12" style={{"maxWidth":880}} content={"<span style='color: var(--color-dominant)'>Vytvořeno přes <a style='color:#fff' href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}